/* Payment header */
.cc-payment-details .cc-payment-details-wrapper h1 {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 5rem;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-header {
  margin-bottom: 10rem;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-header > div {
  display: flex;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-header > div p {
  display: flex;
  margin-bottom: 1rem;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-header > div p span{
  font-weight: bold;
  margin-left: .5rem;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-header > div > div {
  width: calc(50% - 1rem);
}

/* Payment List */

.cc-payment-details .cc-payment-details-wrapper .payment-details-list h2 {
  font-size: 2.8rem;
    font-weight: bold;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div {
  display: flex;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  justify-content: space-between;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div:first-child span {
  font-weight: bold;
  font-size: 1.8rem;
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div:not(:first-child) span {
 font-size: 1.6rem;
}


.cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div:not(:last-child) {
  border-bottom: .1rem solid rgba(216, 216, 216, 0.4);
}

.cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div span {
  width: 25%;
}

/* Mobile list */


@media all and (max-width: 768px) {
  .cc-payment-details .cc-payment-details-wrapper h1 {
    font-size: 3.6rem;
  }

  .cc-payment-details .cc-payment-details-wrapper .payment-details-list h2 {
    font-size: 2.8rem;
  }

  .cc-payment-details .cc-payment-details-wrapper .payment-details-header > div {
    flex-direction: column;
  }
  .cc-payment-details .cc-payment-details-wrapper .payment-details-header > div > div {
    width: 100%;
  }

  .cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div > div,
  .cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div span {
    width: calc(50% - .5rem);
  }

  .cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div > div span {
    width: 100%;
  }

  .cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div > div span:first-child {
    margin-bottom: 1rem;
  }

  .cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div:first-child span {

    font-size: 1.6rem;
  }

  .cc-payment-details .cc-payment-details-wrapper .payment-details-list > div > div:not(:first-child) span {
   font-size: 1.4rem;
  }
}
