.cb-contact .cb-contact-wrapper .cb-contact-header {
  margin-bottom: 10rem;
  }
    .cb-contact .cb-contact-wrapper .cb-contact-header p {
      width: 35%;
      margin: 0;
    }

.cb-contact .cb-contact-wrapper .cb-contact-content {
  display: flex;
  }

    .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-form {
      width: 50%;
      }

        .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-form form input,
        .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-form form textarea,
        .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-form form button {
          display: inherit;
          margin: 0 0 4rem 0;
        }

        .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-form form textarea {
          height: 15rem;
        }

    .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-address {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      }

        .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-address h2 {
          margin-bottom: 4rem;
          }

        .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-address span {
          margin-bottom: 1rem;
          opacity: 0.6;
          color: rgba(0,0,0,1);
          font-family: "SF-Pro-Display-Regular";
          font-size: 18px;
          margin: 0;
          margin-bottom: 2rem;
          }

@media all and (max-width:1024px) and (min-width:769px) {
  .cb-contact .cb-contact-wrapper .cb-contact-header p {
    width: 50%;
  }
}

@media all and (max-width: 768px) {
  .cb-contact .cb-contact-wrapper .cb-contact-header p {
    font-size: 1.8rem;
    width: 100%;
  }

  .cb-contact .cb-contact-wrapper .cb-contact-content {
    flex-direction: column-reverse;
    }

      .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-form,
      .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-address {
        width: 100%;
        align-items: flex-start;
        }

      .cb-contact .cb-contact-wrapper .cb-contact-content .cb-contact-content-address {
        margin-bottom: 5rem;
      }
}