/* Modal */
.cc-get-app-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.9);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cc-get-app-modal .cc-get-app-modal-close {
  position: absolute;
  top: 5rem;
  right: 6rem;
  cursor: pointer;
}

.cc-get-app-modal .cc-get-app-modal-close::after {
  content: "\2716";
  color: #ffffff;
  font-size: 4rem;
}

.cc-get-app-modal .cc-get-app-modal-body {
  padding: 5rem;
  background-color: #ffffff;
  text-align: center;
  width: 60%;
  max-height: 80%;
  overflow-y: auto;

}

.cc-get-app-modal .cc-get-app-modal-body h1 {
  font-size: 4.4rem;
  color: rgba(75, 198, 103, 1);
  margin-bottom: 2rem;
}

.cc-get-app-modal .cc-get-app-modal-body h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.cc-get-app-modal .cc-get-app-modal-body p {
  font-size: 2.4rem;
  color: #000000 !important;
  max-width: 70%;
  margin: 0 auto;
}

.cc-get-app-modal .cc-get-app-modal-body button,
.cc-get-app-modal .cc-get-app-modal-body a {
  margin: 2rem 0 0 0;
}

.cc-get-app-modal .cc-get-app-modal-body a {
  display: block;
}

@media all and (max-width: 768px) {
  .cc-get-app-modal .cc-get-app-modal-close {
    top: 2rem;
    right: 2rem;
  }

  .cc-get-app-modal .cc-get-app-modal-close::after {
    content: "\2716";
    color: #000000;
    font-size: 3rem;
  }

  .cc-get-app-modal .cc-get-app-modal-body {
    padding: 5rem 2rem;
    width: 100%;
    max-height: 100%;
    position: relative
  }

  .cc-get-app-modal .cc-get-app-modal-body p {
    font-size: 2.4rem;
    color: #000000 !important;
    max-width: 100%;
  }
}