.cb-404 .cb-404-wrapper > div {
  margin: 0 auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 3rem;
  background-color: #F9F9F9;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

    .cb-404 .cb-404-wrapper > div span {
      font-size: 20rem;

    }

@media all and (max-width:1024px) and (min-width:769px) {

}

@media all and (max-width: 768px) {
  .cb-404 .cb-404-wrapper > div {

    width: 100%;
    text-align: center;
  }

      .cb-404 .cb-404-wrapper > div span {
        font-size: 10rem;

      }
}