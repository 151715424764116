.user-payment .user-payment-wrapper .user-payment-title {
  font-size: 2.8rem;
  font-weight: bold;
}

/* Desktop */

.user-payment .user-payment-wrapper .user-payments-list .user-payment-list {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  padding-bottom: 3rem;
}

.user-payment .user-payment-wrapper .user-payments-list .user-payment-list:not(:last-child){
  border-bottom: .1rem solid rgba(216, 216, 216, 0.4);
}

.user-payment .user-payment-wrapper .user-payments-list .user-payment-list > div h3 {
  font-size: 1.8rem;
    font-weight: bold;
    letter-spacing: -0.03px;
    line-height: 1.8rem;
    font-family: 'SF-Pro-Display-Bold';
}

.user-payment .user-payment-wrapper .user-payments-list .user-payment-list > div span {
  font-size: 1.6rem;
}

.user-payment .user-payment-wrapper .user-payments-list .user-payment-list > div:last-child {
  display: flex;
  align-items: center;
}

.user-payment .user-payment-wrapper .user-payments-list .user-payment-list > div button {
  margin: 0;
}

/* Mobile */

.user-payment .history-separator {
  width: 100%;
  height: .1rem;
  border: .1rem solid #D8D8D8;
  margin-bottom: 2.5rem;
  opacity: 0.4;
}

.user-payment .user-payment-wrapper .user-mobile-payment-list .user-payment-list {
  display: flex;
  margin-top: 5rem;
  justify-content: space-between;
  padding-bottom: 3rem;
}

.user-payment .user-payment-wrapper .user-mobile-payment-list .user-payment-list:not(:last-child) {
  border-bottom: .1rem solid rgba(216, 216, 216, 0.4);
}

.user-payment .user-payment-wrapper .user-mobile-payment-list .user-payment-list > div {
 width: calc(50% - .5rem);
}

.user-payment .user-payment-wrapper .user-mobile-payment-list .user-payment-list > div h3 {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: -0.03px;
  line-height: 1.8rem;
  font-family: 'SF-Pro-Display-Bold';
  margin-bottom: 1rem;
}

.user-payment .user-payment-wrapper .user-mobile-payment-list .user-payment-list > div span {
  font-size: 1.4rem;
}

.user-payment .user-payment-wrapper .user-mobile-payment-list .user-payment-list > div :nth-child(2) {
  margin-bottom: .5rem;
}

.user-payment .user-payment-wrapper .user-mobile-payment-list .user-payment-list > div span:last-child {
  font-size: 1.4rem;
  color: #7cc961;
}

@media all and (max-width: 768px) {

  .user-payment .user-payment-wrapper {
    margin-bottom: 2rem;
  }

  .user-payment .user-payment-wrapper .user-payment-title {
    font-size: 1.8rem;
  }

}