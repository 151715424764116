.cb-data-deletion .cb-data-deletion-wrapper>div {
  margin-bottom: 5rem;
}

.cb-data-deletion .cb-data-deletion-wrapper > div h1 {
  text-align: center;
  margin-bottom: 7rem;
}

.cb-data-deletion .cb-data-deletion-wrapper > div h3 {
  margin-bottom: 3rem;
}

.cb-data-deletion .cb-data-deletion-wrapper > div ol li {
  margin-bottom: 1.5rem;
  opacity: 0.6;
  color: rgba(0, 0, 0, 1);
  font-family: "SF-Pro-Display-regular";
  font-size: 1.8rem;
  letter-spacing: -0.03px;
  line-height: 2.1rem;
}

.cb-data-deletion .cb-data-deletion-wrapper .step-image {
  max-width: 300px;
  margin: 0 auto;
}