.menu-no-scroll {
  overflow: hidden;
}

/* Error messages */

.cc-error-messages p {
  margin: 0 0 1rem 0;
  color: #ff0000;

}

.cc-success-messages p {
  margin: 0 0 1rem 0;
  color: #16921c;
}

@media all and (max-width:9999999px) and (min-width:1024px) {
  .hide-desktop {
    display: none !important;
  }
}

@media all and (max-width:1024px) and (min-width:769px) {
  .hide-tablet {
    display: none !important;
  }
}

@media all and (max-width: 768px) {
  .hide-mobile {
    display: none !important;
  }
}