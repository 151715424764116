.step-two {
  position: relative;
  padding: 10% 0;
}

.step-two .step-two-wrapper {
  display: flex;
  align-items: center;
}

.step-two .step-two-wrapper .step-two-logos {
  width: 57%;
}

/* Logo position */

.step-two .fade-logo .logo-1 {
  width: 7%;
  padding-top: 7%;
  top: 17%;
  left: 15%;
}

.step-two .fade-logo .logo-2 {
  width: 15.3%;
  padding-top: 15.3%;
  left: 27%;
  bottom: 46%;
}

.step-two .fade-logo .logo-3 {
  width: 13.9%;
  padding-top: 13.9%;
  bottom: 28%;
  left: 9%;
}


.step-two .fade-logo .logo-4 {
  width: 11.1%;
  padding-top: 11.1%;
  bottom: 15%;
  left: 27%;
}

/* Content */

.step-two .step-two-wrapper .step-two-content {
  width: 43%;
}

.step-two .step-two-wrapper .step-two-content .content-label {
  font-family: "SF-Pro-Display-Bold";
  font-weight: bold;
  font-size: 2.4rem;
  opacity: 0.2;
  margin-bottom: 3rem;
}

.step-two .step-two-wrapper .step-two-content h2 {
  width: 70%;
}

.step-two .step-two-wrapper .step-two-content p {
  width: 70%;
}

/* Carousel */
.step-two .step-two-wrapper .step-two-carousel .slide {
  margin: 0 1rem;
}

@media all and (max-width:1024px) and (min-width:769px) {
  .step-two .step-two-wrapper .step-two-content h2 {
    width: 100%;
  }

  .step-two .step-two-wrapper .step-two-content p {
    width: 100%;
  }

  .step-two .fade-logo .logo-1 {
    width: 7%;
    padding-top: 7%;
    top: 33%;
    left: 13%;
  }
}

@media all and (max-width: 768px) {

  .step-two {
    background-color: #F9F9F9;
    padding: 0;
  }

  .step-two .step-two-wrapper {
    display: block;
    text-align: center;
  }

  .step-two .step-two-wrapper .step-two-content {
    padding: 0 2rem;
    width: 100%;
  }

  .step-two .step-two-wrapper .step-two-content .content-label {
    font-family: "SF-Pro-Display-Medium";
    font-weight: initial;
    font-size: 1.8rem;
    opacity: 0.6;
  }

  .step-two .step-two-wrapper .step-two-content h2 {
    width: 100%;
  }

  .step-two .step-two-wrapper .step-two-content p {
    width: 100%;
  }

  .step-two .step-two-wrapper .step-two-carousel {
    margin: 5rem 0;
  }

}