.cb-questions-btn .cb-questions-btn-wrapper a {
  display: flex;
  justify-content: center;
  border-radius: 1.6rem;
  padding: 3rem 0;
  background-color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
}

.cb-questions-btn .cb-questions-btn-wrapper a h2 {
  margin: 0;
  font-size: 2.4rem;
  font-weight: bold;
  position: relative;
}

.cb-questions-btn .cb-questions-btn-wrapper a h2 img {
  width: 2rem;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  left: -4rem;
  top: calc(50% - 1.2rem);
}