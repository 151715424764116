.cc-search-box {
  position: relative;
  width: fit-content;
  margin-right: 3rem;
}

.cc-search-box form {
  display: flex;
}

.cc-search-box form .btn-search-submit {
  position: relative;
  background-color: #f5f5f5;
  outline: none;
  color: transparent;
  width: 5rem;
  appearance: none;
  cursor: pointer;
  border: none;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

.cc-search-box form .btn-search-submit:after {
  content: "";
  width: 2.4rem;
  height: 2.4rem;
  position: absolute;
  background-image: url("../../../assets/icons/search.svg");
  background-repeat: no-repeat;
  background-size: cover;
  top: calc(50% - 1.1rem);
  right: calc(50% - 1.1rem);
  display: inline-block;
}

.cc-search-box input[type="text"] {
  outline: none;
  border: none;
  padding: 0 3rem;
  height: 6rem;
  width: 35rem;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #f5f5f5;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  margin: 0;
}

.cc-search-box input[type="text"]::placeholder {
  color: #000000;
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
  letter-spacing: -0.03px;
  line-height: 18px;
}

@media all and (max-width: 768px) {
  .cc-search-box {
    margin-top: 3rem;
    width: 100%;
    margin-right: 0;
  }

  .cc-search-box input[type="text"] {
    height: 60px;
    width: 100%;
  }
}
