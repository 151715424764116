.cc-change-password .cc-change-password-wrapper {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
  border: .1rem solid rgba(151, 151, 151, 0.4);
  border-radius: 12px;
  padding: 5rem;
}

.cc-change-password .cc-change-password-wrapper form {
  display: flex;
  flex-direction: column;
}

.cc-change-password .cc-change-password-wrapper a,
.cc-change-password .cc-change-password-wrapper button {
 width: 100%;
 margin: 2rem 0 0 0;
}