.cb-curling-corner {
  background-color: #fff;
  width: 100%;
  height: 5.5rem;
  border-radius: 1.5rem;
  position: relative;
  cursor: pointer;
}

.cb-curling-corner .cb-curling-wrapper {
  border: 0.2rem dashed #52c664;
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.cb-curling-corner .curling-content .content-top-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 13%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  font-size: 1.5rem;
  color: #fff;
  opacity: initial;
}

.cb-curling-corner .curling-content .content-bottom-text {
  margin: 0 2.5rem 0 0;
  color: #51c664;
  opacity: initial;
}

.cb-curling-corner .curling-overlay {
  position: absolute;
  width: 80%;
  height: 100%;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  left: 0;
  top: 0;
  border-top-right-radius: 1.2rem;
  background: linear-gradient(90.44deg, #52c664 1.77%, #1c9a30 99.69%);

  animation-name: peelBottom;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.cb-curling-corner .curling-overlay .curling-overlay-corner {
  width: 20%;
  height: 5.85rem;
  position: absolute;
  top: 0rem;
  right: -1.86rem;
  box-shadow: 5px 0px 10px 0px #1413135e;
  background: linear-gradient(90deg, rgba(77, 194, 96, 1) 20%, rgba(147, 223, 147, 1) 41%, rgba(77, 194, 96, 1) 70%);
  z-index: 2;
  transform: rotate(-20deg);
  transform-origin: 100% 100%;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1rem;

  animation-name: peelTop;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@media all and (max-width: 768px) {
  .cb-curling-corner .curling-content .content-bottom-text {
    margin: 0 12rem 0 0;
  }
}

@media all and (max-width: 450px) {
  .cb-curling-corner .curling-content .content-bottom-text {
    margin: 0 4.5rem 0 0;
  }

  .cb-curling-corner .curling-overlay .curling-overlay-corner {
    right: -1.79rem;
  }
}

@media all and (max-width: 350px) {
  .cb-curling-corner .curling-content .content-bottom-text {
    margin: 0 3rem 0 0;
  }
}

/* Initialize animation ------------------------------------------------------ */
/* .cb-curling-corner:hover .curling-overlay {
  animation-play-state: paused;
  width: 60%;
  transition: all 2s linear;
}

.cb-curling-corner:hover .curling-overlay .curling-overlay-corner {
  animation-play-state: paused;
  width: 40%;
  transition: all 2s linear;
} */

@keyframes peelTop {
  45% {
    width: 20%;
  }
  50% {
    width: 25%;
  }
  55% {
    width: 20%;
  }
}

@keyframes peelBottom {
  45% {
    width: 80%;
  }
  50% {
    width: 75%;
  }
  55% {
    width: 80%;
  }
}
