.cc-read-more-text .read-more-top {
  margin: 2rem 0;
}

.cc-read-more-text .read-more-top.show {
}

.cc-read-more-text .read-more-top.hide {
  height: 13rem;
  overflow: hidden;
  position: relative;
}

.cc-read-more-text .read-more-top.hide::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 100%);
  z-index: 3333;
}

/* .cc-read-more-text .read-more-bottom {
  margin: 2rem 0;
} */

.cc-read-more-text .read-more-item:not(:last-child) {
  margin-bottom: 10rem;
}

.cc-read-more-text .read-more-button {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0;
}

.cc-read-more-text .read-more-button img {
  margin-left: 1rem;
}

@media all and (max-width: 768px) {
  .cc-read-more-text .read-more-title {
    text-align: center;
  }
}
