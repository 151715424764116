.cc-thank-you {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cc-thank-you .cc-thank-you-wrapper {
  text-align: center;
  max-width: 70rem;
  margin: 0 auto;
  border: 0.1rem solid grey;
  border-radius: 1.4rem;
  padding: 5rem;
}

.cc-thank-you .cc-thank-you-wrapper h1 {
  margin-bottom: 3rem;
}

.cc-thank-you .cc-thank-you-wrapper h3 {
  color: rgba(67, 192, 101, 1);
}

.cc-thank-you .cc-thank-you-wrapper p {
  margin-bottom: 1rem;
}

@media all and (max-width: 768px) {
  .cc-thank-you .cc-thank-you-wrapper {
    text-align: center;
    max-width: 70rem;
    margin: 0 auto;
    border: 0.1rem solid grey;
    border-radius: 1.4rem;
    padding: 5rem 2rem;
  }
}
