/* Logo position and animation settings--------------------- */

.fade-logo > div {
  position: absolute;
  background-color: #FFFFFF;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  border-radius: 50%;
}

.fade-logo > div .logo-face,
.fade-logo > div .logo-back {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  overflow: hidden;
  border-radius: 50%;
  opacity: 0;

  /* transition: opacity 1s ease-in-out; */
}


/* Logos animation */

/* @keyframes fadeIn {

  0% { opacity: 0; }

  5% { opacity: 1; }

  50% { opacity: 1; }

  55% { opacity: 0; }

  100% { opacity: 0; }

}

@keyframes fadeOut {

  0% { opacity:0; }

  55% { opacity:0; }

  60% { opacity:1; }

  95% { opacity:1; }

  100% { opacity:0; }
} */
