.shops-card {
  /* width: 100%; */
  padding: 2rem 4rem;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 1.2rem;
  height: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.shops-card .shops-card-logo {
  min-height: 72%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shops-card .shops-card-logo img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.shops-card span {
  height: .1rem;
  width: 100%;
  border-bottom: .1rem solid #BEBEBE;
  display: block;
  margin: 2rem 0;
  opacity: 0.4;
}

.shops-card p {
  color: #000000;
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 1.8rem;
  margin: 0;
  opacity: initial;
  text-align: center;
}
