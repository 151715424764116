.btn {
  display: inline-block;
  margin: 1rem 1rem 0 0;
  font-family: "SF-Pro-Display-Bold";
  outline: none;
  cursor: pointer;
}

/* Primary */

.btn.primary {
  padding: 1.9rem 4.4rem;
  border-radius: 1.4rem;
  border: none;
  font-size: 1.8rem;
}

.btn.primary.green {
  background: linear-gradient(180deg, rgba(67, 192, 101, 1) 0%, rgba(97, 213, 108, 1) 100%);
  color: #ffffff;
}

.btn.primary.black {
  background-color: #000000;
  color: #ffffff;
}

.btn.primary.white {
  background-color: #ffffff;
  color: #4dc858;
}

.btn.primary.blue {
  background: rgba(66, 90, 148, 1);
  color: #ffffff;
}

/* Outline */

.btn.outline {
  padding: 1.7rem 4.2rem;
  border-radius: 1.4rem;
  background-color: transparent;
  font-size: 1.8rem;
}

.btn.outline.black {
  color: #000000;
  border: 2px solid #000000;
}

.btn.outline.white {
  color: #ffffff;
  border: 2px solid #ffffff;
}

.btn.outline.grey {
  color: rgba(16, 16, 15, 1);
  border: 1px solid rgba(0, 0, 0, 1);
  margin: 0;
}

/* Submit */

.btn.submit {
  padding: 1.9rem 4.4rem;
  border: none;
  outline: none;
  margin: 0;
  border-radius: 1.4rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: transparent;
}

.btn.submit.black {
  background-color: #000000;
  color: #ffffff;
}

/* Product btn black */

.btn.product.black {
  padding: 1.5rem 1rem;
  border-radius: 1.4rem;
  word-break: break-all;
  width: 100%;
  margin: 0;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  justify-content: center;
  outline: none;
  border: none;
  font-size: 1.8rem;
}

/* Append arrow */

.btn-append-arrow {
  position: relative;
  display: inline-block;
}

.btn-append-arrow::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "→";
  font-family: "SF-Pro-Display-Bold", Fallback, sans-serif;
  right: -3.5rem;
  margin-top: 0.1rem;
}

/* Colors */

.white {
  color: #ffffff;
}

.grey {
  opacity: 0.6;
  color: #000000;
  letter-spacing: -0.03px;
  line-height: 21px;
  margin-left: 2rem;
}

/* Login */

.btn.primary.green.login {
  padding: 1.9rem 4.4rem;
}

/* Read more btn */

.btn.read-more {
  opacity: 0.6;
  color: #000000;
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
  letter-spacing: -0.03px;
  line-height: 1.8rem;
  margin: 0;
  padding: 1.5rem 0;
  width: 34.7rem;
  border-radius: 0.7rem;
}

.btn.read-more.grey {
  border: 0.1rem solid #979797;
  background-color: #ffffff;
  box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.1);
}

.btn.read-more.small {
  border: none;
  padding: 0.5rem;
  width: auto;
}

/* Promo btn */
.cb-promo-btn {
  padding: 1.22rem 3.5rem;
  border-radius: 0.9rem;
  background: linear-gradient(180deg, rgba(67, 192, 101, 1) 0%, rgba(97, 213, 108, 1) 100%);
}

/* Account Actions */
.cc-account-actions-btn {
  font-size: 2rem;
  font-weight: bold;
  color: #7cc961;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
}

/* Filter select dropdown */

.cc-filter {
  width: fit-content;
  position: relative;
  margin-right: 3rem;
}

.cc-filter select {
  width: 30rem;
  padding: 2rem 6rem 2rem 2rem;
  border-radius: 7px;
  background-color: transparent;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: none;
  outline: none;
  cursor: pointer;
  color: #000000;
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
  letter-spacing: -0.03px;
  line-height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cc-filter:after {
  content: "\276F";
  position: absolute;
  transform: rotate(90deg);
  font-size: 1.8rem;
  top: calc(50% - 1.2rem);
  right: 3rem;
  z-index: -1;
}

.cc-filter select option {
  border: none;
  outline: none;
}

@media all and (max-width: 768px) {
  .btn {
    margin: 1rem 0 0 0;
  }

  /* Primary button */
  .btn.primary {
    padding: 1.9rem 4.2rem;
    border-radius: 1.4rem;
  }

  .btn.mobile-full-width {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .btn.outline {
    padding: 1.7rem 4.2rem;
  }

  .btn.submit {
    padding: 1.9rem 4rem;
  }

  .btn.primary.green.login {
    padding: 1.9rem 4.2rem;
  }

  /* Read more btn */

  .btn.read-more {
    width: 100%;
  }

  /* Account Actions */
  .cc-account-actions-btn {
    font-size: 1.8rem;
  }

  /* Filter select dropdown */

  .cc-filter {
    width: auto;
    margin-top: 2rem;
    margin-right: 0;
  }

  .cc-filter select {
    width: 100%;
  }
}
