.fa-hero .fa-hero-wrapper > div p{
  width: 60%;
}

@media all and (max-width:1024px) and (min-width:769px) {
  .fa-hero .fa-hero-wrapper > div p{
    width: 80%;
  }
}

@media all and (max-width: 768px) {
  .fa-hero .fa-hero-wrapper > div p{
    width: 100%;
  }
}