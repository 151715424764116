/* Comission List --------------------------- */
.cc-shop-view .cc-shop-view-wrapper {
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  border-radius: 1.2rem;
  width: 100%;
  padding: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;
}

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list {
  width: 65%;
}

/* Title */

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list h2 {
  margin-bottom: 4rem;
}

/* Tags */
.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 5rem;
}

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-tags span {
  border: 1px solid #bebebe;
  padding: 1rem 2rem;
  border-radius: 10rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

.cc-shop-view .cc-shop-view-wrapper .commission-list-tags button {
  margin-bottom: 0.9rem;
}

/* Commisions */

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list h3 {
  margin-bottom: 5rem;
}

/* .cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-view {
  margin-bottom: 10rem;
} */

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-view-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-view-inner ul {
  width: 100%;
}

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-view-inner ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 0 3rem 0;
  margin: 0 0 3rem 0;
}

.cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-view-inner ul li:not(:last-child) {
  border-bottom: 1px solid #c4c4c4;
}

/* Coupons */

.cc-shop-view .cc-shop-view-wrapper .commission-list-coupon {
  margin-top: 10rem;
}

.cc-shop-view .cc-shop-view-wrapper .commission-list-coupon h3 {
  margin-bottom: 3rem;
}

.cc-shop-view .cc-shop-view-wrapper .commission-list-coupon .commission-list-coupon-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.cc-shop-view .cc-shop-view-wrapper .commission-list-coupon .commission-list-coupon-wrapper > div {
  width: 40%;
}

/* Shop view affiliate --------------------------- */

.cc-shop-view .cc-shop-view-wrapper .shop-view-affiliate {
  width: 35%;
  padding: 5rem;
  position: relative;
}

.cc-shop-view .cc-shop-view-wrapper .shop-view-affiliate::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(6, 1, 1, 0.1);
  width: 0.1rem;
  height: 100%;
}

@media all and (max-width: 1024px) {
  .cc-shop-view .cc-shop-view-wrapper {
    flex-direction: column-reverse;
    padding: 0;
    box-shadow: none;
  }

  /* Comission */

  .cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list .commission-list-view h3 {
    text-align: center;
  }

  /* Affiliate */

  .cc-shop-view .cc-shop-view-wrapper .shop-view-commission-list,
  .cc-shop-view .cc-shop-view-wrapper .shop-view-affiliate {
    width: 100%;
  }

  .cc-shop-view .cc-shop-view-wrapper .shop-view-affiliate {
    padding: 0rem 0rem 4rem 0rem;
  }

  .cc-shop-view .cc-shop-view-wrapper .shop-view-affiliate::before {
    display: none;
  }
}

@media all and (max-width: 768px) {
  .cc-shop-view .cc-shop-view-wrapper .commission-list-coupon h3 {
    text-align: center;
  }
  .cc-shop-view .cc-shop-view-wrapper .commission-list-coupon .commission-list-coupon-wrapper > div {
    width: 100%;
  }
}
