.cc-copy-to-clipboard button {
  padding: 0 3rem 0 6rem;
  border-radius: 1.2rem;
  outline: none;
  margin: 0;
  border: none;
  font-weight: bold;
  cursor: pointer;
  min-height: 5.9rem;
  width: 100%;
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
  position: relative;
}

.cc-copy-to-clipboard button img {
  width: 1.5rem;
  position: absolute;
  top: calc(50% - 0.7rem);
  left: 3rem;
}
