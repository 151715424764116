/* Shop view affiliate --------------------------- */

.shop-view-affiliate-wrapper {
  text-align: center;
}

.shop-view-affiliate-wrapper .shop-view-image {
  margin-bottom: 5rem;
}
/* Discount */

.shop-view-affiliate-wrapper .shop-view-discount p {
  opacity: initial;
}

.shop-view-affiliate-wrapper .shop-view-discount p span {
  display: inline-block;
  margin: 0 0.5rem;
  font-weight: bold;
}

/* Button group */

.shop-view-affiliate-wrapper .shop-view-button-group a,
.shop-view-affiliate-wrapper .shop-view-button-group > div {
  margin: 0 0 3rem 0;
  width: 100%;
}

@media all and (max-width: 1024px) {
  .cc-shop-view .cc-shop-view-wrapper .shop-view-affiliate-wrapper {
    width: 100%;
  }
}

@media all and (max-width: 768px) {
}
