/* Flip Cards */

.flip-card-products .card-1,
.flip-card-products .card-2,
.flip-card-products .card-3 {
  position: absolute;
  top: 0;
  right: 0;
}

.flip-card-products .card-1 {
  top: 16%;
  right: 35%;
  width: 15%;
}

.flip-card-products .card-2 {
  top: 31%;
  right: 16%;
  width: 18%;

}

.flip-card-products .card-3 {
  top: 19%;
  right: 5%;
  width: 10%;
}

/* Flip card animation */

.flip-card-products .flip-card-container {
  width: 100%;
  height: 100%;
  perspective: 1000px;
}

.flip-card-products .flip-card-container .flip-card-inner  {
  width: 100%;
  padding-bottom: 150%;
  position: relative;
  /* transition: all 5s ease; */
  transform-style: preserve-3d;
}

.flip-card-products .flip-card-container .flip-card-inner .flip-card-front,
.flip-card-products .flip-card-container .flip-card-inner .flip-card-back {
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  padding: 3rem;
  box-shadow: 0 .2rem 1rem 0 rgba(0,0,0,0.05);
  border-radius: 1.4rem;
  backface-visibility: hidden;
}

.flip-card-products .flip-card-container .flip-card-inner .flip-card-back {
  transform: rotateY(180deg);
}

/* Card content */
  .flip-card-products .card-summary {
    text-align: center;
  }

  .flip-card-products .card-summary .summary-title {
    font-family: "SF-Pro-Display-Semibold";
    font-weight: 600;
    line-height: 1.8rem;
    overflow: hidden;

  }

  .flip-card-products .card-summary .summary-icon {
    width: 20%;
  }

  .flip-card-products .card-summary .summary-discount {
    color: rgba(0,0,0,1);
    font-family: "SF-Pro-Display-Semibold";
    font-weight: 600;
    letter-spacing: -0.03px;
  }

  .flip-card-products .card-summary .summary-shop {
    opacity: 0.5;
    color: rgba(0,0,0,1);
    font-family: "SF-Pro-Display-Semibold";
    font-weight: 600;
    letter-spacing: -0.03px;
    line-height: 2.4rem;
    margin-top: 5%;
  }

  .flip-card-products .card-1 h3,
  .flip-card-products .card-1 span {
    font-size: 1.4rem;
  }

  .flip-card-products .card-1 h3 {
    height: 3.9rem;
    margin-bottom: 1rem;
  }

  .flip-card-products .card-2 h3,
  .flip-card-products .card-2 span {
    font-size: 1.8rem;

  }

  .flip-card-products .card-2 h3 {
    height: 3.6rem;
    margin-bottom: 2rem;
  }

  .flip-card-products .card-3 h3,
  .flip-card-products .card-3 span {
    font-size: 1rem;
  }

  .flip-card-products .card-3 h3 {
    height: 3.6rem;
    margin-bottom: .5rem;
  }

  @media all and (max-width:1366px) and (min-width:1025px) {

    .flip-card-products .card-1 {
      top: 16%;
      right: 35%;
      width: 14%;
    }

    .flip-card-products .card-2 {
      top: 31%;
      right: 16%;
      width: 18%;
    }

    .flip-card-products .card-3 {
      top: 19%;
      right: 4%;
      width: 11%;
    }
  }

  @media all and (max-width:1024px) and (min-width:769px) {

    .flip-card-products .card-1 {
      top: 26%;
      right: 37%;
      width: 17%;
    }

    .flip-card-products .flip-card-container .flip-card-inner .flip-card-front,
    .flip-card-products .flip-card-container .flip-card-inner .flip-card-back {

      padding: 3rem 1rem;

    }

    .flip-card-products .card-2 {
      top: 41%;
      right: 17%;
      width: 19%;
    }

    .flip-card-products .card-3 {
      top: 29%;
      right: 3%;
      width: 13%;
    }
  }