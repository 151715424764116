.cb-coupons-card {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  cursor: pointer;
  user-select: none;
  height: 35rem;
}

.cb-coupons-card .card-header {
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.cb-coupons-card .card-header img {
  max-height: 100%;
  max-width: 100%;
  width: auto;
}

.cb-coupons-card .card-body {
  margin-bottom: 4rem;
}

.cb-coupons-card .card-body p {
  text-align: center;
  opacity: initial;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 2.3rem;
  overflow: hidden;
  height: 7.1rem;
  margin: 0;
}
