html {
  font-size: 10px;
}

body {
  margin: 0;
  font-size: 1.8rem;
  font-family: "SF-Pro-Display-Regular", Fallback, sans-serif;
}

* {
  box-sizing: border-box;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

figure {
  margin: 0;
}

a {
  font-size: 1.8rem;
  color: #000;
  text-decoration: none;
  font-family: "SF-Pro-Display-Bold", Fallback, sans-serif;
}

p {
  color: #000000;
  margin: 0;
  opacity: 0.6;
  margin-bottom: 3rem;
  /* letter-spacing: -0.03px; */
  line-height: 2.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "SF-Pro-Display-Bold", Fallback, sans-serif;
  margin: 0;
}

h1 {
  font-size: 6.4rem;
  margin-bottom: 5rem;
}

h2 {
  font-size: 3.6rem;
  margin-bottom: 5rem;
}

h3 {
  font-family: "SF-Pro-Display-Regular";
  font-size: 2.4rem;
  margin-bottom: 1rem;
}

img {
  width: 100%;
}

span {
  display: block;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea[name="textarea"],
textarea[name="message"] {
  display: block;
  margin-top: 2rem;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  height: 6rem;
  width: 100%;
  padding: 0 2rem;
  font-size: 1.8rem;
  opacity: initial;
  color: #000000;
}

textarea[name="textarea"],
textarea[name="message"] {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media all and (max-width: 1366px) and (min-width: 1025px) {
  html {
    font-size: 9px;
  }
}

@media all and (max-width: 1024px) and (min-width: 769px) {
  html {
    font-size: 9px;
  }
}

@media all and (max-width: 768px) {
  /* html {
    font-size: 9px;
  } */

  h1 {
    font-size: 3.6rem;
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 2.4rem;
    margin-bottom: 3rem;
  }

  p {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
}
