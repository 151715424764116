.cb-container {
  max-width: 130rem;
  margin: 0 auto;
  padding-left: 2rem;
  padding-right: 2rem;
}

.cb-container.is-full-width {
  max-width: 100%;
  padding: 0;
  margin: 0;
}

.cb-container.small-padding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.cb-container.medium-padding {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.cb-container.large-padding {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

@media all and (max-width: 768px) {

  .cb-container.no-top-padding-mobile {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cb-container.no-side-padding-mobile {
    padding-left: 0;
    padding-right: 0;
  }

  .cb-container.mobile-small-padding {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .cb-container.mobile-medium-padding {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .cb-container.mobile-large-padding {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
}