.cc-coupon-copy {
}

.cc-coupon-copy .cc-coupon-copy-text {
  margin-bottom: 2rem;
}

.cc-coupon-copy .cc-coupon-copy-text p {
  font-size: 1.4rem;
  margin-bottom: 2rem;
  margin: 0;
}

.cc-coupon-copy .cc-coupon-copy-wrapper {
  display: flex;
}

.cc-coupon-copy .cc-coupon-copy-value {
  border: 0.2rem dashed #51c664;
  box-sizing: border-box;
  border-radius: 15px;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-right: 2rem;
}

.cc-coupon-copy .cc-coupon-copy-value span {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.cc-coupon-copy .cc-coupon-copy-value span.cc-coupon-copy-sucess {
  background-color: #51c664;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.cc-coupon-copy .cc-coupon-copy-button button {
  padding: 0 3rem 0 6rem;
  border-radius: 1.2rem;
  outline: none;
  margin: 0;
  border: none;
  font-weight: bold;
  cursor: pointer;
  height: 100%;
  color: #ffffff;
  background-color: #52c664;
  position: relative;
}

.cc-coupon-copy .cc-coupon-copy-button img {
  width: 1.5rem;
  position: absolute;
  top: calc(50% - 0.7rem);
  left: 20%;
}

@media all and (max-width: 768px) {
  .cc-coupon-copy .cc-coupon-copy-wrapper {
    display: block;
  }

  .cc-coupon-copy .cc-coupon-copy-button button {
    width: 100%;
    height: 6rem;
    font-size: 2rem;
    font-weight: bold;
    margin-top: 2rem;
  }
}
