.account-user-info .user-content {
  display: flex;
  margin-bottom: 10rem;
}

.account-user-info .user-content .user-intro {
  width: 40%;
}

.account-user-info .user-content .user-intro h1 {
  font-size: 6.4rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.account-user-info .user-content .user-data {
  width: 60%;
  display: flex;
  align-items: center;
}

.account-user-info .user-content .user-data .user-data-summary {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.account-user-info .user-content .user-data .user-data-summary h3 {
  font-weight: bold;
  font-size: 2.8rem;
  margin-bottom: 2rem;
}

.account-user-info .user-content .user-data .user-data-summary p {
  font-size: 2.4rem;
  opacity: initial;
  margin-bottom: 2rem;
}

.account-user-info .user-content .user-data .user-data-summary .user-data-summary-wallet {
  display: flex;
  margin-bottom: 2rem;
}

.account-user-info .user-content .user-data .user-data-summary .user-data-summary-wallet span {
  font-size: 2.4rem;
  font-weight: bold;
  margin-right: 2rem;
}

.account-user-info .user-content .user-data .user-data-summary .user-data-summary-wallet a {
  font-size: 2.4rem;
  font-weight: bold;
  color: #7CC961;
}

.account-user-info .user-content .user-data .user-data-summary .user-data-summary-actions {
  display: flex;
  align-items: center;
}

.account-user-info .user-content .user-data .user-data-summary .user-data-summary-actions a {
  margin-right: 2rem;
}

.account-user-info .user-content .user-data .user-image {
  margin-left: 3rem;
  width: calc(25% - 3rem);
  padding-bottom: calc(25% - 3rem);
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: .1rem solid #979797;
}

/* User separator */

.account-user-info .user-separator {
  width: 100%;
  height: .1rem;
  border: .1rem solid #D8D8D8;
  margin-bottom: 2.5rem;
  opacity: 0.4;
}

/* Referral History */

.account-user-info .user-info-wrapper .user-referral-history {
  margin-top: 7rem;
  margin-bottom: 2rem;
}

.account-user-info .user-info-wrapper .user-referral-history h2 {
  font-size: 2.8rem;
  font-weight: bold;
}

.account-user-info .user-info-wrapper .user-referral-history-list > div {
  display: flex;
  margin-top: 5rem;
  padding-bottom: 3rem;
}

.account-user-info .user-info-wrapper .user-referral-history-list > div:not(:last-child){
  border-bottom: .1rem solid rgba(216, 216, 216, 0.4);
}

.account-user-info .user-info-wrapper .user-referral-history-list > div > div {
  width: 33.33%;
}

.account-user-info .user-info-wrapper .user-referral-history-list > div h3 {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.03px;
  line-height: 1.8rem;
  font-family: 'SF-Pro-Display-Bold';
}

.account-user-info .user-info-wrapper .user-referral-history-list span.is-in-process {
  color: #C2B312;
}

.account-user-info .user-info-wrapper .user-referral-history-list span.is-paid {
  color: #7CC961;
}

.account-user-info .user-info-wrapper .user-referral-history-list > div span {
  font-size: 1.6rem;

}

.account-user-info .user-info-wrapper .user-referral-history-list > div p {
  opacity: initial;
}

.account-user-info .user-info-wrapper .user-referral-history-list .cc-listing-more-articles {
  text-align: center;
}


@media all and (max-width: 768px) {

  .account-user-info .user-content {
    display: block;
    margin-bottom: 1.5rem;
  }

  .account-user-info .user-content .user-intro {
    width: 100%;
    margin-bottom: 5rem;
  }

  .account-user-info .user-content .user-intro h1 {
    font-size: 3.6rem;
    margin-bottom: 2rem;
  }

  .account-user-info .user-content .user-intro p {
    font-size: 1.8rem;
  }

  .account-user-info .user-content .user-data {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .account-user-info .user-content .user-data .user-data-summary .user-data-summary-wallet {
    margin-bottom: 1rem;
  }

  .account-user-info .user-content .user-data .user-data-summary .user-data-summary-wallet span {
    font-size: 1.8rem;
  }

  .account-user-info .user-content .user-data .user-data-summary .user-data-summary-wallet a {
    font-size: 1.8rem;
    font-weight: bold;
    color: #7CC961;
  }

  .account-user-info .user-content .user-data .user-data-summary {
    display: block;
    width: 75%;
  }

  .account-user-info .user-content .user-data .user-data-summary h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .account-user-info .user-content .user-data .user-data-summary p {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }

  .account-user-info .user-content .user-data .user-data-summary .user-data-summary-actions span {
    font-size: 1.8rem;
    font-weight: bold;
    margin-right: 1rem;
  }

  .account-user-info .user-content .user-data .user-data-summary .user-data-summary-actions a {
    font-size: 1.8rem;
  }

  .account-user-info .user-content .user-data .user-image {
    margin-right: 2rem;
    margin-left: 0;
  }

  /* Refferal History */

  .account-user-info .user-info-wrapper .user-referral-history h2 {
    font-size: 1.8rem;
  }

  .account-user-info .user-info-wrapper .user-referral-history .referral-history-name {
    overflow: hidden;
    margin-right: 1rem;
  }

  .account-user-info .user-info-wrapper .user-referral-history-list > div > div {
    width: 50%;
  }

  .account-user-info .user-info-wrapper .user-referral-history-list > div h3 {
    font-size: 1.6rem;

  }

  .account-user-info .user-info-wrapper .user-referral-history-list > div span {
    font-size: 1.4rem;
  }

}