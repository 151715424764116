.cb-benefits .cb-benefits-wrapper div h3 {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: "SF-Pro-Display-Bold";
}

.cb-benefits .cb-benefits-wrapper div p {
  margin: 0;
  font-family: 'SF-Pro-Display-Medium';
}

.cb-benefits .cb-benefits-wrapper .slick-slider .slick-list .slick-track .slick-slide > div > div {
  outline: none;
  padding-right: 8rem;
}

.cb-benefits .cb-benefits-wrapper .slick-slider .slick-arrow.slick-next::before,
.cb-benefits .cb-benefits-wrapper .slick-slider .slick-arrow.slick-prev::before {
  font-size: 1.8rem;
  color: #000;
  font-family: 'SF-Pro-Display-Bold';
  opacity: initial;
}

.cb-benefits .cb-benefits-wrapper .slick-slider .slick-arrow.slick-next {
  right: 0;
}

.cb-benefits .cb-benefits-wrapper .slick-slider .slick-arrow.slick-prev {
  left: 0;
  color: #000000;
}

@media all and (max-width:1024px) and (min-width:769px) {
  .cb-benefits .cb-benefits-wrapper .slick-slider .slick-list .slick-track .slick-slide > div > div {
    padding-right: 4rem;
  }
}


@media all and (max-width: 768px) {
  .cb-benefits {
    background-color: #F9F9F9;
    box-shadow: inset 0 12px 10px 0 rgba(0,0,0,0.03), 0 2px 12px 0 rgba(0,0,0,0.1);
  }

  .cb-benefits .cb-benefits-wrapper .slick-slider .slick-list .slick-track .slick-slide > div > div {
    padding: 0 6rem;
  }

  .cb-benefits .cb-benefits-wrapper .slick-slider .slick-list .slick-track .slick-slide > div > div p {
    font-size: 1.8rem;
  }
}