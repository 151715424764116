@font-face {
  font-family: SF-Pro-Display-Black;
  src: url("./SF-Pro-Display-Black.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-BlackItalic;
  src: url("./SF-Pro-Display-BlackItalic.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Bold;
  src: url("./SF-Pro-Display-Bold.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-BoldItalic;
  src: url("./SF-Pro-Display-BoldItalic.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Heavy;
  src: url("./SF-Pro-Display-Heavy.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-HeavyItalic;
  src: url("./SF-Pro-Display-HeavyItalic.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Light;
  src: url("./SF-Pro-Display-Light.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-LightItalic;
  src: url("./SF-Pro-Display-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Medium;
  src: url("./SF-Pro-Display-Medium.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-MediumItalic;
  src: url("./SF-Pro-Display-MediumItalic.otf") format("opentype");
}


@font-face {
  font-family: SF-Pro-Display-Regular;
  src: url("./SF-Pro-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-RegularItalic;
  src: url("./SF-Pro-Display-RegularItalic.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Semibold;
  src: url("./SF-Pro-Display-Semibold.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-SemiboldItalic;
  src: url("./SF-Pro-Display-SemiboldItalic.otf") format("opentype");
}


@font-face {
  font-family: SF-Pro-Display-Thin;
  src: url("./SF-Pro-Display-Thin.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-ThinItalic;
  src: url("./SF-Pro-Display-ThinItalic.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-Ultralight;
  src: url("./SF-Pro-Display-Ultralight.otf") format("opentype");
}

@font-face {
  font-family: SF-Pro-Display-UltralightItalic;
  src: url("./SF-Pro-Display-UltralightItalic.otf") format("opentype");
}