.cc-create-account .cc-create-account-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.cc-create-account .cc-create-account-wrapper > div {
  width: calc(50% - 1rem);
  border: .1rem solid rgba(151, 151, 151, 0.4);
  border-radius: 12px;
  padding: 10rem;
}

.cc-create-account .cc-create-account-wrapper .new-account h2,
.cc-create-account .cc-create-account-wrapper .new-account-benefits h2 {
  font-size: 2.8rem;
  font-family: 'SF-Pro-Display-Bold';
  margin-bottom: 2rem;
}


.cc-create-account .cc-create-account-wrapper .new-account p {
  margin-bottom: 3rem;
}

    .cc-create-account .cc-create-account-wrapper .new-account form input {
      margin: 2rem 0 2rem 0;
    }

    .cc-create-account .cc-create-account-wrapper .new-account form label {
      opacity: 0.6;
      font-size: 1.4rem;
  }

.cc-create-account .cc-create-account-wrapper .new-account .terms-and-conditions {
  display: flex;
  align-items: center;
}

.cc-create-account .cc-create-account-wrapper .new-account .terms-and-conditions input {
  border-radius: .4rem;
  opacity: 0.4;
}

.cc-create-account .cc-create-account-wrapper .new-account .terms-and-conditions label {
  display: flex;
  margin-left: 2rem;
  opacity: 0.6;
  font-size: initial;
}

.cc-create-account .cc-create-account-wrapper .new-account .terms-and-conditions label a {
  font-weight: bold;
  margin-left: .5rem;
}

/* .cc-create-account .cc-create-account-wrapper .new-account > div {
  margin-top: 3rem;
} */

.cc-create-account .cc-create-account-wrapper .new-account-benefits > div {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.cc-create-account .cc-create-account-wrapper .new-account-benefits > div > div {
  border: 2px solid #69DD6F;
  width: 3rem;
  height: 3rem;
  margin-right: 3rem;
  border-radius: 50%;
  position: relative;
}

.cc-create-account .cc-create-account-wrapper .new-account-benefits > div > div:after {
  position: absolute;
  content: "";
  top: .4rem;
  left: .9rem;
  display: inline-block;
  transform: rotate(45deg);
  height: 1.2rem;
  width: .6rem;
  border-bottom: .2rem solid #69DD6F;
  border-right: .2rem solid #69DD6F;
}

.cc-create-account .cc-create-account-wrapper .new-account-benefits > div p {
  margin: 0;
  opacity: 0.5;
  color: #000000;
  font-size: 2.4rem;
  font-weight: 300;
  letter-spacing: -0.05px;
  line-height: 2.6rem;
}

@media all and (max-width:1024px) and (min-width:769px) {
  .cc-create-account .cc-create-account-wrapper > div {
    width:100%;
  }

  .cc-create-account .cc-create-account-wrapper .new-account-benefits {
    margin-top: 5rem;
  }
}

@media all and (max-width: 768px) {
  .cc-create-account .cc-create-account-wrapper > div {
    width:100%;
    padding: 2rem;
  }

  .cc-create-account .cc-create-account-wrapper > div a,
  .cc-create-account .cc-create-account-wrapper > div button {
    display: inline-block;
    text-align: center;
    width: 100%;
  }

  .cc-create-account .cc-create-account-wrapper .cc-create-account-actions a {
    margin: 1rem 0 0 0;
  }
}

