/* User invite */

.user-invite {
  display: flex;
  justify-content: space-between;
  border-radius: 1.2rem;
  background: linear-gradient(180deg, rgba(104, 222, 107, 1) 0%, rgba(97, 213, 108, 1) 100%);
  padding: 3rem 6rem;
}

.user-invite p {
  color: #ffffff;
  margin: 0;
  opacity: initial;
  width: 65%;
}

.user-invite button {
  color: #ffffff;
  margin: 0;
  opacity: initial;
  font-size: 1.8rem;
  font-weight: bold;
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  border: .1rem solid #ffffff;
  padding: 2.5rem 0;
  border-radius: 1.2rem;
}
/* Refferal modal */

.user-invite .user-refferal-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.33);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-invite .user-refferal-modal .user-refferal-modal-inner {
  background-color: #ffffff;
  padding: 5rem;
  height: auto;
  width: 762px;
  border-radius: 12px;
  background: linear-gradient(180deg, rgba(67, 192, 101, 1) 0%, rgba(97, 213, 108, 1) 100%);
  box-shadow: 0 14px 30px 0 rgba(0, 0, 0, 0.2);

}

.user-invite .user-refferal-modal .user-refferal-modal-inner .refferal-modal-close {
  color: rgba(0, 0, 0, 1);
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: 3rem;

}

.user-invite .user-refferal-modal .user-refferal-modal-inner .refferal-modal-close:after {
  content: "\2716";
  color: rgba(0, 0, 0, 1);
  font-size: 2.5rem;
  margin-left: 1rem;
}

.user-invite .user-refferal-modal .user-refferal-modal-inner h3 {
  color: #ffffff;
  margin-bottom: 3rem;
}

.user-invite .user-refferal-modal .user-refferal-modal-inner p {
  margin-bottom: 3rem;
  width: 80%;
}

.user-invite .user-refferal-modal .user-refferal-modal-inner .user-refferal-modal-copy {
  display: flex;
  background-color: #ffffff;
  padding: 2rem;
  margin-bottom: 5rem;
  border-radius: 1.4rem;
}

.user-invite .user-refferal-modal .user-refferal-modal-inner .user-refferal-modal-copy input[type="text"] {
  width: 100%;
  margin: 0;
  border: none;
  outline: none;
  box-shadow: none;
}

.user-invite .user-refferal-modal .user-refferal-modal-inner .user-refferal-modal-copy button {
  color: #ffffff;
  background-color: #000000;
  padding: 0 3rem;
  border-radius: 1.2rem;

}

@media all and (max-width: 99999999px) {
  /* User invite */

.user-invite {
  flex-direction: column;
  padding: 0 2rem 2rem 2rem;
  text-align: center;
}

.user-invite p {
  margin-bottom: 3rem;
  font-size: 2rem;
  width: 100%;
}


  /* Refferal modal */

  .user-invite .user-refferal-modal .user-refferal-modal-inner .user-refferal-modal-copy {
    flex-direction: column;
    padding: 1rem;
  }

  .user-invite .user-refferal-modal .user-refferal-modal-inner {
    padding: 1rem;
  }

  .user-invite .user-refferal-modal .user-refferal-modal-inner p {
    width: 100%;

  }

  .user-invite .user-refferal-modal .user-refferal-modal-inner .user-refferal-modal-copy input[type="text"] {
    padding: 0 1rem;
  }

  .user-invite .user-refferal-modal .user-refferal-modal-inner .user-refferal-modal-copy button {
    padding: 1rem;
    margin-top: 1rem;
  }
}