.cc-withdraw-funds .cc-withdraw-funds-wrapper {
  max-width: 50rem;
  margin: 0 auto;
  border: .1rem solid rgba(151, 151, 151, 0.4);
  border-radius: 12px;
  padding: 5rem;
}

.cc-withdraw-funds .cc-withdraw-funds-wrapper .cc-withdraw-funds-header h1 {
  color: rgba(0,0,0,1);
  font-size: 28px;
  font-weight: bold;
  letter-spacing: -0.05px;
  line-height: 34px;
  margin-bottom: 3rem;
}

.cc-withdraw-funds .cc-withdraw-funds-wrapper form {
  display: flex;
  flex-direction: column;
}

.cc-withdraw-funds .cc-withdraw-funds-wrapper a,
.cc-withdraw-funds .cc-withdraw-funds-wrapper button {
 width: fit-content;
 margin: 2rem 0 0 0;
 text-align: left;
}

/* Modal */

.cc-withdraw-funds .cc-withdraw-funds-wrapper .cc-withdraw-funds-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.9);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

    .cc-withdraw-funds .cc-withdraw-funds-wrapper .cc-withdraw-funds-modal > div {
      width: 50%;
      background-color: #ffffff;
      padding: 10rem 5rem;
      text-align: center;
    }

    .cc-withdraw-funds .cc-withdraw-funds-wrapper .cc-withdraw-funds-modal > div h1,
    .cc-withdraw-funds .cc-withdraw-funds-wrapper .cc-withdraw-funds-modal > div h2 {
      color: rgba(0,0,0,1);
      font-family: "SF-Pro-Display-Bold";
      font-size: 28px;
      font-weight: bold;
      text-align: center;
      margin-bottom: .5rem;
    }

    .cc-withdraw-funds .cc-withdraw-funds-wrapper .cc-withdraw-funds-modal > div p {
      margin-top: 3rem;
    }

@media all and (max-width: 768px) {
  .cc-withdraw-funds .cc-withdraw-funds-wrapper {
    padding: 2rem;
  }

  .cc-withdraw-funds .cc-withdraw-funds-wrapper .cc-withdraw-funds-modal > div {
    width: 100%;
    padding: 10rem 2rem;

  }

  .cc-withdraw-funds .cc-withdraw-funds-wrapper a,
  .cc-withdraw-funds .cc-withdraw-funds-wrapper button {
    width: 100%;
    margin: 2rem 0 0 0;
    text-align: center;
  }
}