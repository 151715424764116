/* User history */

.user-history .user-history-wrapper {
  /* margin-top: 5rem; */
}

.user-history .user-history-wrapper h2 {
  font-size: 2.8rem;
  font-weight: bold;
}

.user-history .user-history-wrapper .history-list .list-item {
  height: 10rem;
  display: flex;
  margin-top: 5rem;
  justify-content: space-between;
  border-bottom: .1rem solid rgba(216, 216, 216, 0.4);
  padding-bottom: 3rem;
}

.user-history .user-history-wrapper .history-list .list-item:last-child {
  border-bottom: none;
}

.user-history .user-history-wrapper .history-list .list-item > div {
 width: 15%;
}

.user-history .user-history-wrapper .history-list .list-item h3 {
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.03px;
  line-height: 1.8rem;
  font-family: 'SF-Pro-Display-Bold';
}

.user-history .user-history-wrapper .history-list .list-item span {
  font-size: 1.6rem;
}

.user-history .user-history-wrapper .history-list .list-item  .list-item-image {
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
}

.user-history .user-history-wrapper .history-list .list-item  .list-item-image img{
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.user-history .user-history-wrapper .history-list .list-item span.is-in-process {
  color: #C2B312;
}

.user-history .user-history-wrapper .history-list .list-item span.is-canceled {
  color: #EA4D8C;
}

.user-history .user-history-wrapper .history-list .list-item span.is-paid {
  color: #7CC961;
}


/* User separator */

.user-history .history-separator {
  width: 100%;
  height: .1rem;
  border: .1rem solid #D8D8D8;
  margin-bottom: 2.5rem;
  opacity: 0.4;
}

/* User separator */

.user-history .user-history-wrapper .user-history-sort-group {
  display: flex;
}

.user-history .user-history-wrapper .user-history-sort-group .cc-sort-user-history {
  margin-right: 2rem;
}

@media all and (max-width: 768px) {
  .user-history .user-history-wrapper{
    margin-bottom: 2rem;
  }
  .user-history .user-history-wrapper h2 {
    font-size: 1.8rem;
  }

  .user-history .user-history-wrapper .history-list .list-item {
    align-items: baseline;
  }

  .user-history .user-history-wrapper .history-list .list-item > div {
    width: calc(50% - .5rem);
   }

  .user-history .user-history-wrapper .history-list .list-item h3 {
    font-size: 1.6rem;
    margin-bottom: .5rem;
  }

  .user-history .user-history-wrapper .history-list .list-item span {
    font-size: 1.4rem;
  }

   .user-history .user-history-wrapper .history-list .list-item > div span:last-child {
    font-size: 1.4rem;
    margin-top: .5rem;
   }
}
