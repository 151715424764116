.cc-shop-related .cc-shop-related-wrapper .slick-slider {
  padding: 0rem 3rem 0rem 2rem;
}

.cc-shop-related .cc-shop-related-wrapper .slick-track {
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
}

.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-next,
.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-prev {
  height: 100%;
}

.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-next::before,
.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-prev::before {
  color: #000;
}

.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-next {
  right: 0;
}

.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-next:before {
  content: url("../../../../assets/icons/chevron-right.svg");
}

.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-prev {
  left: 0;
}

.cc-shop-related .cc-shop-related-wrapper .slick-slider .slick-arrow.slick-prev:before {
  content: url("../../../../assets/icons/chevron-left.svg");
}

@media all and (max-width: 768px) {
  .cc-shop-related-wrapper h3 {
    text-align: center;
  }
}
