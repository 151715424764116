.cc-time-availability {
  display: flex;
  align-items: center;
}

.cc-time-availability img {
  width: 1.2rem;
  margin-right: 1.5rem;
}

.cc-time-availability p {
  margin: 0;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  opacity: initial;
}

@media all and (max-width: 768px) {
  .cc-time-availability {
    width: 100%;
    justify-content: center;
  }
}
