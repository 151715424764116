.carousel {
  width: 100%;
  overflow: hidden;
}

.slides {
  display: flex;
  flex-flow: row nowrap;
  animation: moveAnim linear infinite;
}
.slides:hover {
  animation-play-state: paused !important;
}

.slide {
  flex: 0 0 320px;
}

@keyframes moveAnim {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-2380px, 0, 0);
  }
}
