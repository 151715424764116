.cc-price-sort {
  width: fit-content;
  position: relative;
}

.cc-price-sort select {
  width: 30rem;
  padding: 2rem 6rem 2rem 2rem;
  border-radius: 7px;
  background-color: transparent;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
  border: none;
  outline: none;
  cursor: pointer;

  color: #000000;
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
  letter-spacing: -0.03px;
  line-height: 18px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cc-price-sort:after {
  content: "\276F";
  position: absolute;
  transform: rotate(90deg);
  font-size: 1.8rem;
  top: calc(50% - 1.2rem);
  right: 2.4rem;
  z-index: -1;

}

.cc-price-sort select option {
  border: none;
  outline: none;
}