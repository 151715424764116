.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-header {
  display: flex;
  padding: 0 6rem;
  position: relative;
  margin-bottom: 5rem;
  cursor: default;
  pointer-events: none;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-header h2 {
  margin: 0;
  font-size: 2.4rem;
  font-weight: bold;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-header img {
  width: 2rem;
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  left: 2rem;
  top: calc(50% - 1.2rem);
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box {
  box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
  padding: 4rem 6rem;
  border-radius: 1.2rem;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box:not(:last-child){
  margin-bottom: 3rem;
  cursor: pointer;
  user-select: none;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box h3 {
  display: inline-block;
  font-family: "SF-Pro-Display-Regular";
  position: relative;
  margin-bottom: 0;
  font-weight: normal;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box p,
.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box ul li {
  opacity: 0.6;
  color: rgba(0,0,0,1);
  font-family: "SF-Pro-Display-Medium";
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: -0.03px;
  line-height: 28px;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box p:last-child{
  margin-bottom: 0;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box ul {
  margin-bottom: 3rem;
  list-style-type: disc;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box > div {
  width: 70%;
  margin-top: 3rem;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box h3.closed:after {
  content: '\002B';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -3rem;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box h3.open:after {
  content: '\2212';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -3rem;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box > div.is-visible {
  display: block;
}

.cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-box > div.is-hidden {
  display: none;
}

@media all and (max-width: 768px) {

  .cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-header {
    padding: 4rem 0;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
    border-radius: 1.6rem;
  }

  .cb-toggle-text .cb-toggle-text-wrapper .cb-toggle-text-header img {
    position: initial;
    margin-right: 3rem;
  }

}