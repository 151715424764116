/* Hero -------------------------------------------------------------------------*/

.coupons-listing .coupons-wrapper .coupons-hero {
  margin-bottom: 5rem;
}

.coupons-listing .coupons-wrapper .coupons-hero h1 {
  font-size: 6.4rem;
}

.coupons-listing .coupons-wrapper .coupons-hero p {
  width: 50%;
  font-size: 1.8rem;
}

.coupons-listing .coupons-wrapper .coupons-hero .sorting-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

/* Listing -------------------------------------------------------------------------*/

.coupons-listing .coupons-wrapper .coupons-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.coupons-listing .coupons-wrapper .coupons-list .coupons-list-item {
  width: calc(25% - 20px);
}

@media all and (max-width: 1024px) and (min-width: 769px) {
  .coupons-listing .coupons-wrapper .coupons-list .coupons-list-item {
    width: calc(33.33% - 20px);
  }
}

@media all and (max-width: 768px) {
  .coupons-listing .coupons-wrapper .coupons-hero p {
    width: 100%;
  }

  .coupons-listing .coupons-wrapper .coupons-list .coupons-list-item {
    width: 100%;
  }

  .coupons-listing .coupons-wrapper .coupons-hero .sorting-group {
    display: block;
  }
}
