.cc-incative-card {
  position: relative;
  width: 100%;
  height: 100%;
}

.cc-incative-card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  user-select: none;
  display: none;
}

.cc-incative-card:hover .cc-incative-card-overlay {
  display: flex;
}

.cc-incative-card-overlay .card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background-color: #000000;
  border-radius: 1.2rem;
}

.cc-incative-card-overlay .card-overlay-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 1.2rem;
  color: #ffffff;
  display: flex;
}

.cc-incative-card-overlay .card-overlay-content h3 {
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "SF-Pro-Display-Regular";
}

.cc-incative-card-overlay .card-overlay-content p {
  font-size: 1.4rem;
  opacity: initial;
  letter-spacing: -0.03px;
  line-height: 18px;
  font-weight: initial;
  font-family: "SF-Pro-Display-Regular";
  margin-bottom: 0;
  color: #ffffff;
}

.cc-incative-card-overlay .card-overlay-content a {
  display: inline-block;
  color: #ffffff;
  font-size: 1.4rem;
  margin-top: 2rem;
  opacity: 1;
}
