.cc-mobile-homepage {
  background: linear-gradient(
    180deg,
    rgba(104, 222, 107, 1) 0%,
    rgba(112, 220, 121, 1) 100%
  );
}
/* .cc-mobile-homepage .cc-mobile-homepage-wrapper {

    } */

.cc-mobile-homepage .cc-mobile-homepage-wrapper .cc-mobile-homepage-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem 5rem 2rem;
  text-align: center;
}

.cc-mobile-homepage
  .cc-mobile-homepage-wrapper
  .cc-mobile-homepage-hero
  .cc-mhp-user-image {
  width: 15rem;
  height: 15rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-bottom: 2rem;
  border: 0.1rem solid #ffffff;
}

.cc-mobile-homepage
  .cc-mobile-homepage-wrapper
  .cc-mobile-homepage-hero
  .cc-mhp-user-name {
  color: rgba(255, 255, 255, 1);
  font-family: "SF-Pro-Display-Bold";
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.cc-mobile-homepage
  .cc-mobile-homepage-wrapper
  .cc-mobile-homepage-hero
  .cc-mhp-user-email {
  color: rgba(255, 255, 255, 1);
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
  margin-bottom: 1rem;
  opacity: initial;
}

.cc-mobile-homepage
  .cc-mobile-homepage-wrapper
  .cc-mobile-homepage-hero
  .cc-mhp-user-redraw {
  display: flex;
  align-items: center;
}

.cc-mobile-homepage
  .cc-mobile-homepage-wrapper
  .cc-mobile-homepage-hero
  .cc-mhp-user-redraw
  span {
  margin-right: 1rem;
  color: rgba(255, 255, 255, 1);
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
}

.cc-mobile-homepage
  .cc-mobile-homepage-wrapper
  .cc-mobile-homepage-hero
  .cc-mhp-user-redraw
  a {
  color: rgba(255, 255, 255, 1);
  font-family: "SF-Pro-Display-Bold";
  font-size: 1.8rem;
  font-weight: bold;
}

.cc-mobile-homepage .cc-mobile-homepage-wrapper .cc-mobile-homepage-menu {
  padding: 0 2rem 0 2rem;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cc-mobile-homepage .cc-mobile-homepage-wrapper .cc-mobile-homepage-menu a,
.cc-mobile-homepage
  .cc-mobile-homepage-wrapper
  .cc-mobile-homepage-menu
  button {
  height: 7.8rem;
  width: 100%;
  border-radius: 1.2rem;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  color: rgba(0, 0, 0, 1);
  font-family: "SF-Pro-Display-Bold";
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.03px;
  line-height: 30px;

  margin-top: 2rem;
}
