/* Hero -----------------------------------------------------------*/

.shops-listing .shops-wrapper .shops-hero {
  margin-bottom: 5rem;
}

.shops-listing .shops-wrapper .shops-hero p {
  width: 35%;
  font-size: 1.8rem;
}

.shops-listing .shops-wrapper .shops-hero .sorting-group {
  display: flex;
  align-items: center;
}

/* List -----------------------------------------------------------*/

.shops-listing .shops-wrapper .shops-list {
  display: grid;
  grid-template-columns:
    calc(25% - 1.5rem) calc(25% - 1.5rem) calc(25% - 1.5rem)
    calc(25% - 1.5rem);
  grid-gap: 2rem;
}

/* More products */

.shops-listing .shops-wrapper .list-more-shops {
  text-align: center;
  margin-top: 5rem;
}

@media all and (max-width: 1024px) and (min-width: 769px) {
  .shops-listing .shops-wrapper .shops-hero p {
    width: 50%;
  }

  .shops-listing .shops-wrapper .shops-list {
    grid-template-columns: calc(33.33% - 1rem) calc(33.33% - 1rem) calc(33.33% - 1rem);
    grid-gap: 1.5rem;
  }
}

@media all and (max-width: 768px) {
  .shops-listing .shops-wrapper {
    margin-bottom: 5rem;
  }

  .shops-listing .shops-wrapper .shops-hero p {
    width: 100%;
  }

  .shops-listing .shops-wrapper .shops-hero .sorting-group {
    display: block;
  }

  .shops-listing .shops-wrapper .shops-list {
    grid-template-columns: 100%;
    grid-gap: 2rem;
  }
}
