.cc-edit-account .cc-edit-account-wrapper {}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-title {
  margin-bottom: 2.5rem;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-title h1 {
  font-family: "SF-Pro-Display-Bold";
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-title p {
  opacity: 0.6;
  color: rgba(0, 0, 0, 1);
  font-family: "SF-Pro-Display-regular";
  font-size: 18px;
  letter-spacing: -0.03px;
  line-height: 21px;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-body {
  width: 50%;

}

.cc-edit-account .user-separator {
  width: 100%;
  height: .1rem;
  border: .1rem solid #D8D8D8;
  margin-bottom: 2.5rem;
  opacity: 0.4;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-action-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 3rem 0;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-action-group button {
  margin: 0 1rem 0 0;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-action-group>h2 {
  margin: 0;
}


.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-body>div:not(:last-child) {
  margin-bottom: 5rem;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-account-body>div h2 {
  color: rgba(0, 0, 0, 1);
  font-family: "SF-Pro-Display-Bold";
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: -0.03px;
  line-height: 2.1rem;
  margin-right: 2rem;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-user-image {
  margin-top: 5rem;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-user-image .edit-user-image-frame {
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 2rem;
  border: .1rem solid #D8D8D8;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-user-info>div>p {
  color: rgba(0, 0, 0, 1);
  font-family: "SF-Pro-Display-Regular";
  font-size: 1.8rem;
  letter-spacing: -0.03px;
  line-height: 1.8rem;
  opacity: initial;
  margin: 0;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-user-info .cc-edit-user-info-input {
  display: flex;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-user-info .cc-edit-user-info-input>input:first-child {
  margin-right: 2rem;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-user-password form {
  text-align: right;
}

.cc-edit-account .cc-edit-account-wrapper .cc-edit-user-password form label {
  opacity: 0.6;
  font-size: 1.4rem;
}

@media all and (max-width: 768px) {

  .cc-edit-account .cc-edit-account-wrapper .cc-edit-account-body {
    width: 100%;
  }

  .cc-edit-account .cc-edit-account-wrapper .cc-edit-user-image .edit-user-image-frame {
    width: 10rem;
    height: 10rem;
  }

  .cc-edit-account .cc-edit-account-wrapper .cc-edit-account-action-group button.btn {
    margin-top: 1rem;
  }

  .cc-edit-account .cc-edit-account-wrapper .cc-edit-user-info .cc-edit-user-info-input {
    flex-wrap: wrap;
  }

  .cc-edit-account .cc-edit-account-wrapper .cc-edit-user-info .cc-edit-user-info-input>input:first-child {
    margin-right: 0;
  }


  .cc-edit-account .cc-edit-account-wrapper .cc-edit-user-password form {
    text-align: left;
  }
}