.cc-forgotten-password {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.cc-forgotten-password .cc-forgotten-password-wrapper {
  max-width: 40rem;
  margin: 0 auto;
  text-align: center;
  border: 0.1rem solid rgba(151, 151, 151, 0.4);
  border-radius: 12px;
  padding: 5rem;
}

.cc-forgotten-password .cc-forgotten-password-wrapper form {
  display: flex;
  flex-direction: column;
}

.cc-forgotten-password .cc-forgotten-password-wrapper a,
.cc-forgotten-password .cc-forgotten-password-wrapper button {
  width: 100%;
  margin: 2rem 0 0 0;
}
