/* Modal */
.cc-coupon-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cc-coupon-modal .cc-coupon-modal-close {
  position: absolute;
  top: 5rem;
  right: 6rem;
  cursor: pointer;
}

.cc-coupon-modal .cc-coupon-modal-close::after {
  content: "\2716";
  color: #ffffff;
  font-size: 4rem;
}

.cc-coupon-modal .cc-coupon-modal-underlay {
  background-color: rgb(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: pointer;
}

/* Body */

.cc-coupon-modal .cc-coupon-modal-body {
  background-color: #ffffff;
  width: 60%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 1.5rem;
}

/* Content */

.cc-coupon-modal .cc-coupon-modal-body .cc-body-content {
  display: flex;
  align-items: center;
  gap: 4rem;
  padding: 5rem;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-content .content-image {
  min-width: 30%;
  background: #ffffff;
  box-shadow: 0 -0.1rem 1.4rem -0.3rem rgba(0, 0, 0, 0.26);
  border-radius: 1.4rem;
  padding: 2rem;
  height: 23rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-content .content-elements {
  width: 100%;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-content .content-elements h2 {
  font-size: 2.8rem;
  margin-bottom: 2rem;
}

/* Coupone code spacing */

.cc-coupon-modal
  .cc-coupon-modal-body
  .cc-body-content
  .cc-coupone-copy-spacing {
  margin: 4rem 0;
}

/* Coupone affiliate */
.cc-coupon-modal
  .cc-coupon-modal-body
  .cc-body-content
  .cc-coupone-affiliate
  a {
  font-weight: 600;
  font-size: 1.7rem;
  letter-spacing: 0.06em;
}

/* Footer */

.cc-coupon-modal .cc-coupon-modal-body .cc-body-footer {
  display: flex;
  align-items: center;
  gap: 5rem;
  background-color: rgba(196, 196, 196, 0.3);
  padding: 5rem;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-text {
  width: 65%;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-text h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-text p {
  font-size: 1.4rem;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-link {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-link a {
  font-family: "SF-Pro-Display-Regular";
  font-weight: 600;
  font-size: 1.8rem;
  margin: 0;
  font-weight: initial;
}

@media all and (min-width: 769px) and (max-width: 1200px) {
  .cc-coupon-modal .cc-coupon-modal-body {
    width: 85%;
  }
}

@media all and (max-width: 768px) {
  .cc-coupon-modal .cc-coupon-modal-close {
    top: 2rem;
    right: 2rem;
  }

  .cc-coupon-modal .cc-coupon-modal-close::after {
    content: "\2716";
    color: #000000;
    font-size: 3rem;
  }

  .cc-coupon-modal .cc-coupon-modal-body {
    width: 100%;
    max-height: 100%;
    position: relative;
  }

  .cc-coupon-modal .cc-coupon-modal-body .cc-body-content {
    display: block;
    padding: 4rem 2rem;
    text-align: center;
  }

  .cc-coupon-modal .cc-coupon-modal-body .cc-body-content .content-image {
    border: none;
    box-shadow: none;
    border-radius: 1.4rem;
    padding: 1rem 0;
  }

  .cc-coupon-modal .cc-coupon-modal-body .cc-body-content .content-elements h2 {
    font-size: 2.4rem;
  }

  .cc-coupon-modal .cc-coupon-modal-body .cc-body-footer {
    display: block;
    padding: 5rem 2rem;
  }

  .cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-text {
    text-align: center;
  }

  .cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-text,
  .cc-coupon-modal .cc-coupon-modal-body .cc-body-footer .cc-body-footer-link {
    width: 100%;
  }
}
