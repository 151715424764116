/* Modal */
.cc-warning-modal-settings {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cc-warning-modal-settings .cc-warning-modal-settings-close {
  position: absolute;
  top: 5rem;
  right: 6rem;
  cursor: pointer;
}

.cc-warning-modal-settings .cc-warning-modal-settings-close::after {
  content: "\2716";
  color: #ffffff;
  font-size: 4rem;
}

.cc-warning-modal-settings .cc-warning-modal-settings-underlay {
  background-color: rgb(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: pointer;
}

/* Body */

.cc-warning-modal-settings .cc-warning-modal-settings-body {
  background-color: #ffffff;
  width: 60%;
  max-height: 80%;
  overflow-y: auto;
  border-radius: 1.5rem;
  padding: 10rem;
}

/* Content */
.cc-warning-modal-settings-wrapper {
  display: flex;
  align-items: center;
}

.cc-warning-modal-settings-image {
  margin-right: 10rem;
}

.cc-warning-modal-settings-content p {
  font-size: 18px;
  text-align: center;
  margin: 0;
}

.cc-warning-modal-settings-content img {
margin: 3rem 0;
}

@media all and (min-width: 769px) and (max-width: 1200px) {
  .cc-warning-modal-settings .cc-warning-modal-settings-body {
    width: 85%;
  }
}

@media all and (max-width: 768px) {
  .cc-warning-modal-settings .cc-warning-modal-settings-close {
    top: 2rem;
    right: 2rem;
  }

  .cc-warning-modal-settings .cc-warning-modal-settings-close::after {
    content: "\2716";
    color: #000000;
    font-size: 3rem;
  }

  .cc-warning-modal-settings .cc-warning-modal-settings-body {
    width: 100%;
    max-height: 100%;
    position: relative;
    padding: 5rem 2rem;
    margin: 0 2rem;
  }

  .cc-warning-modal-settings-wrapper {
    flex-wrap: wrap;
    justify-content: center;
  }

  .cc-warning-modal-settings-image {
    margin-right: 0;
    margin-bottom: 3rem;
  }
}
