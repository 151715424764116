.cc-login {
  background: linear-gradient(
    180deg,
    rgba(104, 222, 107, 1) 0%,
    rgba(112, 220, 121, 1) 100%
  );
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cc-login > div {
  width: 100%;
}

.cc-login .cc-login-wrapper > div {
  width: calc(50% - 1rem);
  /* border: .1rem solid rgba(151, 151, 151, 0.4);
  border-radius: 12px;
  padding: 10rem; */
}

.cc-login .cc-login-wrapper .login .cc-login-title,
.cc-login .cc-login-wrapper .new-account h2 {
  font-size: 2.8rem;
  font-family: "SF-Pro-Display-Bold";
  margin-bottom: 4rem;
  text-align: center;
  color: #ffffff;
}

.cc-login .cc-login-wrapper .login p {
  margin-bottom: 3rem;
}

.cc-login .cc-login-wrapper .login .kep-login-facebook {
  border-radius: 1.4rem;
  margin: 2rem 0;
  outline: none;
  font-family: "SF-Pro-Display-Bold";
  text-transform: none;
  font-size: 1.8rem;
  background-color: rgba(66, 90, 148, 1);
  padding: 2rem 4.4rem;
}

.cc-login .cc-login-wrapper .login .cc-error-messages {
  margin-top: 3rem;
}

.cc-login .cc-login-wrapper .login .terms-wrapper {
  margin-top: 3rem;
  
}
.cc-login .cc-login-wrapper .login .terms-wrapper a {
  display: inline-block;
  text-align: initial;
  margin: 0;
  width: fit-content;
}

.cc-login .cc-login-wrapper .login .terms-wrapper p {
 margin: 0;
 display: flex;
  flex-direction: column;
}

.cc-login .cc-login-wrapper .new-account p {
  margin-bottom: 13.8rem;
}

@media all and (max-width: 1024px) and (min-width: 769px) {
  .cc-login .cc-login-wrapper > div {
    width: 100%;
  }

  .cc-login .cc-login-wrapper .new-account {
    margin-top: 3rem;
  }
}

@media all and (max-width: 999999px) {
  .cc-login .cc-login-wrapper > div {
    width: 100%;
  }

  .cc-login .cc-login-wrapper .login a,
  .cc-login .cc-login-wrapper .login button {
    width: 100%;
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    display: inline-block;
  }
}
