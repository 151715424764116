.cc-loading-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}

.cc-loading-spinner img {
  width: 10rem;
}