/* Layout */

.footer .footer-wrapper {
  display: flex;
}

.footer .footer-wrapper h2 {
  font-weight: bold;
  font-size: 1.8rem;
  margin-bottom: 3rem;
}

/* About */
.footer .footer-wrapper .footer-info {
  width: 30%;
  padding-right: 2rem;
}

.footer .footer-wrapper .footer-info p {
  font-size: 1.8rem;
  opacity: initial;
  line-height: 3.6rem;
}

/* Nav */
.footer .footer-wrapper .footer-nav {
  width: 25%;
  padding-right: 2rem;
}

.footer .footer-wrapper .footer-nav a {
  font-family: "SF-Pro-Display-Regular";
  font-size: 18px;
  letter-spacing: -0.03px;
  line-height: 36px;
}

/* Social */
.footer .footer-wrapper .footer-social {
  width: 20%;
  display: flex;
}

.footer .footer-wrapper .footer-social > a:not(:last-child) {
  margin-right: 2rem;
}

.footer .footer-wrapper .footer-social a img {
  width: 4.6rem;
}

.footer .footer-wrapper .footer-copyright {
  padding: 4rem 0;
  margin-top: 8rem;
  background-color: #F8F8F8;
}

.footer .footer-wrapper .footer-copyright p {
  margin: 0;
}

@media all and (max-width:1024px) and (min-width:769px) {
  /* Nav */
  .footer .footer-wrapper .footer-nav {
    width: 33.33%;
  }

  /* Social */
  .footer .footer-wrapper .footer-social {
    width: 33.33%;
  }
}

@media all and (max-width: 768px) {
  /* Social */
  .footer .cb-container {
    padding: 0 !important;
  }

  .footer .footer-wrapper {
    flex-direction: column;
  }

  .footer .footer-wrapper .footer-social {
    width: 100%;
    justify-content: center;
    padding: 0;
    margin-top: 4rem;
  }

  .footer .footer-wrapper .footer-copyright {
   width: 100%;
   text-align: center;
   margin-top: 4rem;
  }

  

  .footer .footer-wrapper .footer-copyright a {
   
    
    font-family: "SF-Pro-Display-Regular", Fallback, sans-serif;
    display: block;
    margin-bottom: 2rem;
  }

  .footer .footer-wrapper .footer-copyright a:first-of-type {
    font-size: 2rem;
  }

  .footer .footer-wrapper .footer-copyright a:last-of-type {
    font-size: 1.8rem;
  }

  .footer .footer-wrapper .footer-copyright p:first-of-type {
    margin-bottom: .5rem;
  }
  .footer .footer-wrapper .footer-copyright p:last-child {
    margin-top: 2rem;
  }
}
