/* Listing header */

.products-listing .products-listing-wrapper .products-listing-header {
  margin-bottom: 5rem;
}

.products-listing .products-listing-wrapper .products-listing-header p {
  width: 35%;
}

.products-listing .products-listing-wrapper .products-listing-header .products-listing-header-sort-area {
  display: flex;
  align-items: center;
}

/* List header */

.products-listing .products-listing-wrapper .products-listing-list .list-header {
  background-color: #70DC79;
  display: flex;
  padding: 3rem 4rem;
  border-radius: 1.2rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-header p {
  margin: 0;
  flex-grow: 1;
  color: #FFFFFF;
  opacity: initial;
}

.products-listing .products-listing-wrapper .products-listing-list .list-header a {
  margin-left: 3rem;
  color: #FFFFFF;
  font-size: 1.8rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-header a:nth-child(2) {
  font-family: "SF-Pro-Display-Bold";
}

.products-listing .products-listing-wrapper .products-listing-list .list-header a:nth-child(3) {
  font-family: "SF-Pro-Display-Regular";
  font-weight: initial;
}

/* List product */

.products-listing .products-listing-wrapper .products-listing-list .list-header {
  margin-bottom: 5rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
  border-radius: 1.2rem;
  padding: 3rem 4rem;
  height: 20rem;
  margin-top: 5rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-image {
  width: calc(15% - 3rem);
  margin-right: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-info {
  width: calc(50% - 3rem);
  margin-right: 3rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-info h3 {
  color: rgba(0,0,0,1);
  font-family: "SF-Pro-Display-Semibold";
  font-size: 2rem;
  font-weight: 600;
  height: 5.1rem;
  overflow: hidden;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-info .product-info-text {
  opacity: 0.6;
  color: rgba(0,0,0,1);
  font-family: "SF-Pro-Display-Regular";
  font-size: 18px;
  margin: 0;
  height: 6.6rem;
  overflow: hidden;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-price {
  width: calc(15% - 3rem);
  margin-right: 3rem;
  text-align: center;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-price .price-cut {
  margin-bottom: 1rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-price .price {
  font-family: 'SF-Pro-Display-Semibold';
  opacity: initial;
  margin-bottom: 1rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-price .discount {
  font-family: 'SF-Pro-Display-Semibold';
  opacity: initial;
  margin-bottom: 0;
  color: #26D4A0;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-shop {
  width: 20%;
  text-align: center;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-shop p {
 opacity: initial;
 margin-bottom: 1rem;
}

.products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-shop span {
  padding-top: 0.12rem;
  margin-left: 1rem;
 }

 @media all and (max-width:1367px) {
  .products-listing .products-listing-wrapper .products-listing-list .list-products .list-product .product-info p {
    height: 7.3rem;
  }
 }

@media all and (max-width:1024px) {

  .products-listing .products-listing-wrapper .products-listing-header p {
    width: 50%;
  }
  /* Mobile list */

  .products-listing .products-listing-wrapper .products-listing-list .list-header p {
    margin-bottom: 2rem;
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products {
    padding: 0;
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product {
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.1);
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.1);
    border-radius: 1.2rem;
    padding: 2rem;
    margin-top: 3rem;
    height: 30rem;
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-image {
    width: calc(40% - .5rem);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 47%;
    margin-right: 1rem;
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-image img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-info {
    width: calc(60% - .5rem);
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-info h3,
  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-info p {
    font-size: 1.4rem;
    font-family: "SF-Pro-Display-Semibold";
    color: #000000;
    opacity: initial;
    overflow: hidden;
  }



  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-info h3 {
    height: 5.1rem;
    margin-bottom: 1rem;
  }


  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-info p.discount {
    color: #26D4A0;
    font-family: "SF-Pro-Display";
    font-size: 1.4rem;
    font-weight: 600;
    letter-spacing: -0.03px;
    line-height: 18px;
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-shop {
    width: 100%;

  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-shop a,
  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-shop button {
    width: 100%;
    text-align: center;
    font-size: 1.8rem;
  }

  .products-listing .products-listing-wrapper .products-listing-list .list-products .mobile-list-product .product-shop span {
    padding-top: 0.12rem;
    margin-left: 1rem;
   }

}

@media all and (max-width: 768px) {
  .products-listing .products-listing-wrapper {
    margin-bottom: 5rem;
  }

  /* Listing header */

  .products-listing .products-listing-wrapper .products-listing-header {
    width: 100%;
  }

  .products-listing .products-listing-wrapper .products-listing-header p {
    width: 100%;
    font-size: 1.8rem;
  }
}