.step-three {
  background-color: #f9f9f9;
  box-shadow: inset 0 12px 10px 0 rgba(0, 0, 0, 0.03),
    0 2px 12px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  padding: 10% 0;
  overflow: hidden;
}

.step-three .step-three-wrapper .step-three-content {
  max-width: 45%;
  padding: 10rem 2rem;
}

.step-three .step-three-wrapper .step-three-content .content-label {
  font-family: "SF-Pro-Display-Bold";
  font-weight: bold;
  font-size: 2.4rem;
  opacity: 0.2;
  margin-bottom: 3rem;
}

/* Carousel */

.step-three .step-three-wrapper .step-three-carousel .slide {
  outline: none;
  padding: 0 1rem;
  background-color: #ffffff;
  margin: 0 1rem;
  border-radius: 1.4rem;
}

.step-three .step-three-wrapper .step-three-carousel .slide > div {
  background-color: #ffffff;
  border-radius: 1.4rem;
  padding: 3rem 5rem;
}

/* Carousel card content */

.step-three .step-three-wrapper .step-three-carousel .card-summary {
  text-align: center;
  font-family: "SF-Pro-Display-Semibold";
}

.step-three
  .step-three-wrapper
  .step-three-carousel
  .card-summary
  .summary-title {
  font-size: 1.2rem;
  font-weight: 600;
  height: 3rem;
  overflow: hidden;
}

.step-three
  .step-three-wrapper
  .step-three-carousel
  .card-summary
  .summary-icon {
  width: 15%;
  margin: 0 auto;
}

.step-three
  .step-three-wrapper
  .step-three-carousel
  .card-summary
  .summary-discount {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.step-three
  .step-three-wrapper
  .step-three-carousel
  .card-summary
  .summary-shop {
  font-size: 1.2rem;
  opacity: 0.5;
  color: rgba(0, 0, 0, 1);
  font-weight: 600;
  letter-spacing: -0.03px;
  line-height: 2.4rem;
}

@media all and (max-width: 768px) {
  .step-three {
    padding: 0;
    margin-bottom: 10rem;
    box-shadow: none;
    text-align: center;
  }

  .step-three .step-three-wrapper .step-three-content {
    max-width: 100%;
    text-align: center;
    padding: 0 2rem 5rem 2rem;
  }

  .step-three .step-three-wrapper .step-three-content .content-label {
    opacity: 0.6;
    color: rgba(0, 0, 0, 1);
    font-family: "SF-Pro-Display-Medium";
    font-size: 1.8rem;
    font-weight: 500;
  }

  .step-three .step-three-wrapper .step-three-content h2 {
    margin-bottom: 2rem;
  }

  .step-three .step-three-wrapper .step-three-content p {
    margin-bottom: 1rem;
  }

  .step-three .step-three-wrapper .step-three-carousel {
    margin-bottom: 5rem;
  }
}
