.cb-side-image .cb-side-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cb-side-image .cb-side-image-wrapper > div {
  width: 50%;
}

.cb-side-image .cb-side-image-wrapper .cb-side-image-content {
  padding-right: 5rem;
}

.cb-side-image .cb-side-image-wrapper .cb-side-image-video {
  margin: 2rem 0;
  cursor: pointer;
}

.cb-side-image
  .cb-side-image-wrapper
  .cb-side-image-video
  .cb-side-image-video-modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.9);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cb-side-image
  .cb-side-image-wrapper
  .cb-side-image-video
  .cb-side-image-video-modal
  iframe {
  width: 60%;
  height: 70%;
}

.cb-side-image
  .cb-side-image-wrapper
  .cb-side-image-video
  .cb-side-image-video-modal
  .video-modal-close {
  position: absolute;
  top: 5rem;
  right: 6rem;
}

.cb-side-image
  .cb-side-image-wrapper
  .cb-side-image-video
  .cb-side-image-video-modal
  .video-modal-close:after {
  content: "\2716";
  color: #ffffff;
  font-size: 4rem;
}

@media all and (max-width: 768px) {
  .cb-side-image .cb-side-image-wrapper {
    flex-direction: column;
  }

  .cb-side-image .cb-side-image-wrapper > div {
    width: 100%;
  }

  .cb-side-image .cb-side-image-wrapper .cb-side-image-content {
    padding-right: 0;
  }

  .cb-side-image .cb-side-image-wrapper .cb-side-image-content p {
    font-size: 1.8rem;
  }

  .cb-side-image
    .cb-side-image-wrapper
    .cb-side-image-video
    .cb-side-image-video-modal
    iframe {
    width: 95%;
    height: 50%;
  }

  .cb-side-image
    .cb-side-image-wrapper
    .cb-side-image-video
    .cb-side-image-video-modal
    .video-modal-close {
    right: calc(50% - 3.2rem);
  }
}
