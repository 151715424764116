
/* Layout */

.header {
  position: relative;

}

.header .header-wrapper {
  display: flex;
  align-items: center;
}

.header .header-wrapper .header-nav {
  flex-grow: 1;
}

/* Logo */

.header .header-wrapper .header-logo {
  margin-right: 4rem;
  z-index: 999999;
}

.header .header-wrapper .header-logo a {
  font-size: 2.8rem;
  font-weight: bold;
}

/* Navigation */

.header .header-wrapper .header-nav .horizontal-nav ul > li:not(:last-child) {
  margin-right: 2rem;
}

.header .header-wrapper .header-nav .horizontal-nav ul > li a {
  padding-bottom: 1rem;
  font-family: 'SF-Pro-Display-Medium';
  font-size: 1.8rem;
  opacity: 0.6;
}

.header .header-wrapper .header-nav .horizontal-nav ul > li .active {
  border-bottom: .3rem solid rgba(97,213,108,1);
}

.header .header-wrapper .header-nav .horizontal-nav ul > li a:hover {
  border-bottom: .3rem solid rgba(97,213,108,1);
}

/* Account */

.header .header-wrapper .header-account {
  margin-right: 4rem;
}

.header .header-wrapper .header-account a {
  font-family: 'SF-Pro-Display-Medium';
  opacity: 0.6;
}

/* Header mobile */
  /* Header mobile */
  .header .header-wrapper .header-mobile-toggle {
    z-index: 999999;
  }

  /* Burger menu button */
    #burger-menu {
      width: 3.5rem;
      height: 2.5rem;
      position: relative;
      margin-top: .5rem;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

      caret-color: rgba(0,0,0,0);
    }

    #burger-menu span {
      display: block;
      position: absolute;
      height: .2rem;
      width: 100%;
      background: #000000;
      border-radius: .9rem;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    #burger-menu span:nth-child(1) {
      top: 0px;
    }

    #burger-menu span:nth-child(2),#burger-menu span:nth-child(3) {
      top: .9rem;
    }

    #burger-menu span:nth-child(4) {
      top: 1.8rem;
    }

    #burger-menu.open span:nth-child(1) {
      top: .9rem;
      width: 0%;
      left: 50%;
    }

    #burger-menu.open span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    #burger-menu.open span:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    #burger-menu.open span:nth-child(4) {
      top: .9rem;
      width: 0%;
      left: 50%;
    }

  /* Menu */

  .header .header-mobile-nav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 15rem 0 0 0;
    background-color: #ffffff;
    z-index: 99999;
    display: flex;
    flex-direction: column;
  }

  .header .header-mobile-nav div {
    overflow-y: auto;
    padding: 0 2rem 5rem 2rem;
    width: 100%;
    height: 100%;
  }

  .header .header-mobile-nav div .vertical-nav {
    flex-grow: 1;
  }

  .header .header-mobile-nav div .vertical-nav ul li {
    margin-bottom:  2rem;
  }

  .header .header-mobile-nav div .vertical-nav ul li:first-child,
  .header .header-mobile-nav div .vertical-nav ul li:nth-child(5) {
    position: relative;
    margin-bottom: 3rem;
  }

  .header .header-mobile-nav div .vertical-nav ul li:first-child:after,
  .header .header-mobile-nav div .vertical-nav ul li:nth-child(5):after {
    content: "";
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    height: .1rem;
    width: 20%;
    background-color: #68de6b;
  }

  .header .header-mobile-nav div button,
  .header .header-mobile-nav div .vertical-nav ul li a {
    color: #000000;
    font-family: "SF-Pro-Display-Regular";
    font-size: 3.2rem;
    font-weight: bold;
    letter-spacing: -0.06px;
    line-height: 50px;
  }

  .header .header-mobile-nav div button {
    outline: none;
    border: none;
    background: transparent;
    padding: 0;
  }

  /* .header .header-mobile-nav div > nav:first-child ul li a {
    color: rgba(67,192,101,1) ;
  } */

@media all and (max-width: 999999999999999px) {

  /* Logo */

  .header .header-wrapper .header-logo {
    margin-right: 0;
    flex-grow: 1;
  }
}

