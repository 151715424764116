.get-app-sms .get-app-sms-wrapper {
  display: flex;
  align-items: center;
}

.get-app-sms .get-app-sms-wrapper h2 {
  font-size: 6.4rem;
  width: 60%;
}

.get-app-sms .get-app-sms-wrapper p {
  width: 70%;
}

.get-app-sms .get-app-sms-wrapper>div:first-child {
  width: 60%;
}

.get-app-sms .get-app-sms-wrapper .get-app-sms-content .cc-success-messages p {
  color: #000000;
}

.get-app-sms .get-app-sms-wrapper .get-app-sms-content form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.get-app-sms .get-app-sms-wrapper .get-app-sms-content form>div {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  margin: 0 2rem 2rem 0;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  background-color: #ffffff;
  border-radius: 1.4rem;
}

.get-app-sms .get-app-sms-wrapper .get-app-sms-content form button {
  margin: 0 0 2rem 0;
}

.get-app-sms .get-app-sms-wrapper .get-app-sms-content input[type='tel'] {
  height: 6rem;
  width: 100%;
  outline: none;
  border: none;
  background-color: white;
  font-size: 1.8rem;
  box-shadow: none;
  padding: 0 0 0 2rem;
  margin: 0 0 0 0;
}

@media all and (max-width: 768px) {
  .get-app-sms {
    background: transparent !important;
    box-shadow: none !important;
  }

  .get-app-sms .get-app-sms-wrapper {
    flex-direction: column;
  }

  .get-app-sms .get-app-sms-wrapper>div {
    width: 100%;
    text-align: center;
  }

  .get-app-sms .get-app-sms-wrapper h2 {
    font-size: 2.4rem;
    width: 100%;
  }

  .get-app-sms .get-app-sms-wrapper p {
    width: 100%;
  }
}