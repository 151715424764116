.cc-scroll-top-btn {
  position: fixed;
  bottom: 10%;
  right: 2rem;
  z-index: 9999;
}

.cc-scroll-top-btn button {
  padding: 0;
  margin: 0;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  font-family: "SF-Pro-Display-Bold";
  font-size: 1.8rem;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(180deg, rgba(67, 192, 101, 1) 0%, rgba(97, 213, 108, 1) 100%);
  border: none;
  outline: none;
  position: relative;
  appearance: none;
  user-select: none;
}

.cc-scroll-top-btn button::after {
  content: "\276F";
  position: absolute;
  transform: rotate(-90deg);
  font-size: 1.8rem;
  top: calc(50% - 1.2rem);
  right: calc(50% - 0.4rem);
}

@media all and (max-width: 768px) {
  .cc-scroll-top-btn {
    bottom: 5%;
  }
}
